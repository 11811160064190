<template>
  <main
    id="content"
    class="main-content page-questions pt-0 bg-oct"
    role="main"
  >
    <div
      class="
        page-questions__header
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <router-link to="/banco-de-questoes" class="link fs-24">
        <i class="fas fa-chevron-left mr-3" /> Voltar
      </router-link>
      <span style="color: #fff; font-size: 20px">Banco de Questões</span>
      <ul class="list-inline m-0">
        <li class="list-inline-item mr-4">Rascunho (1)</li>
      </ul>
    </div>

    <div class="page-questions__content">
      <!-- Start Content center -->
      <div class="content-right px-4" style="margin: auto">
        <div
          class="card card-questions mb-4"
          style="border: 6px solid #ddd; padding: 0"
        >
          <div class="card-body mb-3" style="padding: 0">
            <div style="background: #efefef; padding: 20px">
              <div class="row">
                <!--form class="block__form has-tooltip"-->
                <div class="col-md-4">
                  <div class="form-group full-select">
                    <label
                      >Tipo
                      <i
                        class="help ml-1"
                        data-toggle="tooltip"
                        title="Some tooltip text!"
                    /></label>
                    <select2
                      id="tipo"
                      v-model="questao.tipo"
                      :settings="{ multiple: false, placeholder: 'Selecione' }"
                      :options="tipos"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group full-select">
                    <label
                      >Nível de dificuldade
                      <i
                        class="help ml-1"
                        data-toggle="tooltip"
                        title="Some tooltip text!"
                    /></label>
                    <select2
                      id="nivel"
                      v-model="questao.nivel"
                      :settings="{ multiple: false, placeholder: 'Selecione' }"
                      :options="niveis"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group full-select">
                    <label for="disciplina1"
                      >Componente Curricular
                      <i
                        class="help ml-1"
                        data-toggle="tooltip"
                        title="Some tooltip text!"
                    /></label>
                    <select2
                      id="disciplina1"
                      v-model="disciplinaSelecionada"
                      @change="loadAssuntos(disciplinaSelecionada)"
                      :settings="{ multiple: false, placeholder: 'Selecione' }"
                      :options="disciplinas"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group full-select">
                    <label for="habilidade"
                      >Habilidade
                      <i
                        class="help ml-1"
                        data-toggle="tooltip"
                        title="Some tooltip text!"
                    /></label>
                    <select2
                      id="habilidade"
                      v-model="assuntosSelecionados"
                      :settings="{ multiple: true, placeholder: 'Selecione' }"
                      :options="assuntos"
                    />
                  </div>
                </div>
              </div>
              <!--end row-->
            </div>
            <!--end background-->

            <div style="padding: 20px">
              <p class="card-title">Enunciado</p>

              <div class="content mb-2">
                <text-editor
                  v-model="editorData"
                />
              </div>

              <div class="form-group form-group-repeater d-flex flex-column">
                <div v-if="questao.tipo == 'Objetiva'">
                  <p class="card-title">Alternativas</p>
                  <div
                    v-for="(alternativa, index) in questao.alternativas"
                    :key="alternativa.id"
                    class="input-group mb-2"
                  >
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <input
                          v-model="alternativaCorreta"
                          :value="alternativa.id"
                          type="radio"
                          aria-label="Checkbox for following text input"
                          name="alternativaCorreta"
                          @change="toggleAlternativa(index)"
                        />
                      </div>
                      <span class="input-group-text"
                        >{{ letters[index] }})</span
                      >
                    </div>

                  <text-editor
                    v-model="alternativa.texto"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-danger btn-sm"
                        type="button"
                        style="height: 38px"
                        @click.prevent="deleteAlternativa(index)"
                      >
                        <i class="far fa-trash-alt" />
                      </button>
                    </div>
                  </div>

                  <button
                    class="btn btn-outline btn-outline__sec ml-auto mt-3"
                    @click="addAlternativa"
                  >
                    <i class="mr-1" data-fa-i2svg="">
                      <svg
                        style="height: 20px"
                        class="svg-inline--fa fa-plus fa-w-14"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="plus"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                        />
                      </svg>
                    </i>
                    Adicionar
                  </button>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="float-right">
                      <button
                        type="button"
                        class="btn btn-classic"
                        @click.prevent="salvar"
                      >
                        Salvar
                      </button>
                      <!-- <button
                        type="button"
                        class="btn btn-secondary m-2"
                        @click="voltaQuestoesProva"
                      >
                        Cancelar
                      </button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Content center -->
      </div>
    </div>
  </main>
</template>

<script>
import Select2 from "v-select2-component";
import TextEditor from "@/components/global/text-editor/TextEditor";
import Swal from "sweetalert2";
import store from "@/store";
import { constantes } from "@/constants";

export default {
  components: {
    Select2,
    TextEditor
  },

  data() {
    return {
      alternativaDeletada: false,
      questao: {
        disciplina: null,
        assunto: null,
      },
user: [],
      alternativaCorreta: 0,
      disciplinaSelecionada: null,
      disciplinas: [],
      niveis: ["Fácil", "Médio", "Difícil"],
      assuntosSelecionados: [],
      assuntos: [],
      tipos: ["Objetiva", "Descritiva"],
      letters: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j"],
      editorData: "",
    };
  },
  created() {
    this.$root.$emit("Spinner::show");
    this.loadQuestao();
    // this.loadAssuntos(this.disciplinaSelecionada)
  },
  methods: {
    voltaQuestoesProva() {
      this.$router.push("/banco-de-questoes");
    },
        loadUser() {
      this.$api
        .get("users/info")
        .then((user) => {
          this.user = user;
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        });
    },
    deleteAlternativa(index) {
      if (!this.alternativaDeletada) {
        Swal.fire({
          title:
            "Fique atento.<br/>Para excluir uma alternativa<br/>definitivamente é precisa salvar a questão.",
          position: "center",
          timer: 3000,
          showConfirmButton: false,
          timerProgressBar: true,
          toast: true,
          icon: "success",
        });

        this.alternativaDeletada = true;
      }

      if (this.questao.alternativas.length == 1) {
        this.alternativaDeletada = false;
      }

      this.questao.alternativas.splice(index, 1);
    },
    toggleRespostas() {
      this.showRespostas = !this.showRespostas;
    },
    addAlternativa() {
      if (this.questao.alternativas.length < 10) {
        var idx = this.questao.alternativas.length + 1;
        var novaAlternativa = { id: idx, texto: "", isCorreta: false };
        this.questao.alternativas.push(novaAlternativa);
      }
    },
    toggleAlternativa(index) {
      this.questao.alternativas.map(function (entry) {
        entry.isCorreta = false;
      });

      this.questao.alternativas[index].isCorreta = true;
    },
    salvar() {
      this.questao.disciplina.id = this.disciplinaSelecionada;
      this.questao.assuntos = this.assuntosSelecionados;
      this.questao.enunciado = this.editorData;

      if (this.questao.enunciado == "") {
        alert("Informe um enunciado!");
        return;
      }

      if (this.questao.disciplina.id == "") {
        alert("Informe um componente curricular!");
        return;
      }

      if (this.questao.assuntos.length <= 0) {
        alert("Informe uma habilidade!");
        return;
      }

      if (this.questao.tipo == "Objetiva") {
        if (this.questao.alternativas.length < 2) {
          Swal.fire(
            "Oops...",
            "Informe pelo menos duas alternativas!",
            "error"
          );
          return;
        }
        if (this.alternativaCorreta == 0) {
          Swal.fire(
            "Oops...",
            "Marque uma das alternativas como correta!",
            "error"
          );
          return;
        }
      }


      if (this.questao.tipo == "Descritiva") {
        this.questao.alternativas = [];
      }

      let possuiAlternativaSemTexto = this.questao.alternativas.some(alternativa => !alternativa.texto);

      if (possuiAlternativaSemTexto) {
        Swal.fire(
          "Oops...",
          "Todas as alternativas devem ser preenchidas!",
          "error"
        );
        return;
      }

      this.$root.$emit("Spinner::show");

      this.$api
        .put("edita-questao/" + this.$route.params.id, this.questao)
        .then(() => {
          this.$root.$emit("Spinner::hide");

          Swal.fire({
            position: "center",
            icon: "success",
            title: "Questão salva com sucesso!",
            showConfirmButton: false,
            timer: 1500,
          });
          this.voltaQuestoesProva();
        })
        .catch((error) => {
          this.$root.$emit("Spinner::hide");
          if (error.response.status == 400) {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">' +
                error.response.data.detail +
                "</span>",
              showConfirmButton: false,
              timer: 3500,
            });
          } else if (error.response.status == 401) {
            store.dispatch("auth/ActionSignOut");
            window._Vue.$router.push({ name: "login" });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">' +
                error.response.data.violations[0].message +
                "</span>",
              showConfirmButton: false,
              timer: 2500,
            });
          }
        });
    },
    loadQuestao() {
      this.$api.get("questaos/" + this.$route.params.id).then((response) => {
        this.questao = response.data;
        this.editorData = this.questao.enunciado;
        this.disciplinaSelecionada = this.questao.disciplina.id;

        console.log(this.questao)

        this.assuntosSelecionados = [];
        var questoesIds = [];
        this.questao.assuntos.forEach(function (value) {
          questoesIds.push(value.id);
        });
        this.assuntosSelecionados = questoesIds;

        var idAlternativaIsCorreta = null;
        this.questao.alternativas.map(function (item) {
          if (item.isCorreta) {
            idAlternativaIsCorreta = item.id;
          }
        });

        this.alternativaCorreta = idAlternativaIsCorreta;
        this.loadDisciplinas();
        this.loadUser();
      });
    },
    loadDisciplinas() {
      this.$api.get("disciplinas").then((response) => {
        console.log(response.data);
        response.data.forEach((res) => {
          if (res.trashAt == null) {
            this.disciplinas.push(res);
          }
        });
        this.$root.$emit("Spinner::hide");

        this.loadAssuntos(this.disciplinaSelecionada);
      });
    },
    loadAssuntos(id) {
      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      const data = {};
      this.$api.post("assuntos-disciplina/" + id, data).then((response) => {
        if (
          idInstituicao == 0
        ) {
          this.assuntos = response.data.filter(res => res.instituicao == null);
        } else {
          this.assuntos = response.data.filter(res => res.criadoPor != null && (!res.instituicao || res.criadoPor.email == this.user.data.user.email || res.instituicao.id == idInstituicao));
        }
        this.$root.$emit("Spinner::hide");
      });
    },
  },
};
</script>